<template>
  <Create
    :title="$t('app.add_quiz')"
    :endpoint="endpoint"
    :route="route"
    :type="evaluationTypes.QUIZ"
    :resource-value="value"
    :rules="rules"
    :options="options"
    :localized-fields="localizedFields"
  />
</template>

<script>
import { useI18n } from "vue-i18n";
// Composables
import useQuiz from "@/composables/useQuiz";
// Components
import Create from "../Create";
// Constants
import evaluationTypes from "@/constants/evaluationTypes";

export default {
  components: {
    Create
  },
  setup() {
    // MISC
    const { t } = useI18n();

    // CUSTOM COMPOSABLES
    const {
      endpoint,
      route,
      value,
      rules,
      options,
      localizedFields
    } = useQuiz();

    // Constants
    const documentTitle = `${t("app.add")} - ${t("app.quizzes", 2)} - ${t(
      "app.evaluations",
      2
    )} - ${t("app.teaching")}`;

    return {
      evaluationTypes,
      documentTitle,
      // useQuiz
      endpoint,
      route,
      value,
      rules,
      options,
      localizedFields
    };
  }
};
</script>
