<template>
  <div class="grid grid-cols-2 gap-x-8 gap-y-6">
    <LocalizedInput
      component="VText"
      :model-value="value.name"
      :errors="v.name"
      :lang="lang"
      :readonly="readonly"
      field="name"
      class="col-span-2"
      @update:modelValue="onUpdate"
      @keyup.enter="onSave"
    />

    <!-- <LocalizedInput
      component="VWysiwyg"
      :model-value="value.details"
      :errors="v.details"
      :label="$t('app.details')"
      :readonly="readonly"
      :lang="lang"
      class="col-span-2"
      field="details"
      @update:modelValue="onUpdate"
    /> -->

    <VCheckbox
      v-if="isMultipleChoices"
      v-model="v.correct_answer.$model"
      :label="$t('app.correct_answer')"
      :readonly="readonly"
      true-value="Yes"
      false-value="No"
      :errors="v.correct_answer.$errors"
      @keyup.enter="onSave"
    />

    <VRadio
      v-else
      ref="radio"
      v-model="v.correct_answer.$model"
      :label="$t('app.correct_answer')"
      :readonly="readonly"
      :name="question.id"
      true-value="Yes"
      false-value="No"
      :errors="v.correct_answer.$errors"
      @keyup.enter="onSave"
    />

    <!-- <VSwitch
      v-model="v.sys_admin_locked.$model"
      :disabled="!isSysAdmin"
      :label="$t('app.sys_admin_locked')"
      :readonly="readonly"
      true-value="Yes"
      false-value="No"
      :errors="v.sys_admin_locked.$errors"
      @keyup.enter="onSave"
    /> -->
  </div>
</template>

<script>
import { ref, computed, inject } from "vue";
// Composables
import useOptions from "@/composables/useOptions";
import useForm from "@/composables/useForm";
import useAnswer from "@/composables/useAnswer";
// Mixins
import FormMixin from "@/mixins/FormMixin";

export default {
  mixins: [FormMixin],
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    question: {
      type: Object,
      required: true
    }
  },
  emits: ["click:save"],
  setup(props, context) {
    // Inject
    const _ = inject("lodash");
    const lang = inject("lang");

    // Data
    const radio = ref(null);
    const id = ref(_.uniqueId());

    // Computed
    const isMultipleChoices = computed(() => {
      return props.question.answer_type === ALL_OPTIONS.MULTIPLE_CHOICE.value;
    });

    // Composables
    const { ALL_OPTIONS } = useOptions();
    // eslint-disable-next-line
    const { value, rules, options, localizedFields } = useAnswer(props.question);
    const { v, onUpdate, onSave, stringifiedValue } = useForm(props, context, {
      value,
      rules,
      localizedFields,
      lang
    });

    // Methods
    const getData = async () => {
      const isValid = await v.value.$validate();

      if (!isValid) {
        return;
      }

      return stringifiedValue.value;
    };

    return {
      lang,
      id, // Parent component uses this method. DO NOT REMOVE.
      radio, // Parent component uses this method. DO NOT REMOVE.
      getData, // Parent component uses this method. DO NOT REMOVE.
      isMultipleChoices,
      // useAnswer
      value,
      options,
      // useLocalizedInputs
      v,
      onUpdate,
      onSave
    };
  }
};
</script>
