<template>
  <div>
    <div class="flex justify-between items-center mt-10">
      <VTitle :title="title" class="section-title" />

      <LangDropdown :lang="lang" @update="onUpdateLang" />
    </div>

    <Form
      :is-loading="isLoading"
      :lang="lang.value"
      :resource-value="resourceValue"
      :rules="rules"
      :type="type"
      :options="options"
      :localized-fields="localizedFields"
      @click:cancel="onClickCancel"
      @click:save="onClickSave"
    />
  </div>
</template>

<script>
// Composables
import useLangDropdown from "@/composables/useLangDropdown";
import useCreate from "@/composables/useCreate";
// Components
import VTitle from "@/components/VTitle";
import LangDropdown from "@/components/LangDropdown";
import Form from "./Form";
// Constants
import evaluationTypes from "@/constants/evaluationTypes";

export default {
  components: {
    VTitle,
    Form,
    LangDropdown
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    endpoint: {
      type: String,
      required: true
    },
    route: {
      type: String,
      required: true
    },
    resourceValue: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    },
    localizedFields: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      required: true,
      validator: value =>
        [evaluationTypes.QUIZ, evaluationTypes.OBSERVATION].includes(value)
    }
  },
  setup(props) {
    // Composables
    const { lang, updateLang } = useLangDropdown();
    const { isLoading, onClickCancel, onClickSave } = useCreate(
      props.endpoint,
      props.route
    );

    return {
      // useLangDropdown
      lang,
      onUpdateLang: updateLang,
      // useCreate
      isLoading,
      onClickCancel,
      onClickSave
    };
  }
};
</script>
