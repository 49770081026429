<template>
  <div class="grid grid-cols-2 gap-x-8 gap-y-6">
    <!-- <LocalizedInput
      component="VText"
      :model-value="value.name"
      :errors="v.name"
      :label="$t('app.name')"
      :lang="lang"
      field="name"
      class="col-span-2"
      @update:modelValue="onUpdate"
      @keyup.enter="onSave"
    /> -->

    <LocalizedInput
      component="VWysiwyg"
      :model-value="value.details"
      :errors="v.details"
      :label="detailsLabel"
      :readonly="readonly"
      :lang="lang"
      class="col-span-2"
      field="details"
      @update:modelValue="onUpdate"
    />

    <VText
      v-model="v.grade.$model"
      :label="$t('app.grade')"
      :readonly="readonly"
      type="number"
      :min="0"
      :errors="v.grade.$errors"
      @keyup.enter="onSave"
    />

    <VSelect
      v-model="v.answer_type.$model"
      :readonly="readonly"
      :label="$t('app.answer_type')"
      :errors="v.answer_type.$errors"
      :options="answerTypeOptions"
    />

    <VFile
      v-model="v.image_file_id.$model"
      :readonly="readonly"
      :options="options.image_file_id"
      accept="image/*"
      :label="$t('app.image_file_id')"
      :errors="v.image_file_id.$errors"
    />

    <!-- <VSwitch
      v-model="v.sys_admin_locked.$model"
      :disabled="!isSysAdmin"
      :label="$t('app.sys_admin_locked')"
      true-value="Yes"
      false-value="No"
      :errors="v.sys_admin_locked.$errors"
    /> -->
  </div>
</template>

<script>
import { computed, inject } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useOptions from "@/composables/useOptions";
import useQuestion from "@/composables/useQuestion";
import useRequirement from "@/composables/useRequirement";
import useForm from "@/composables/useForm";
// Mixins
import FormMixin from "@/mixins/FormMixin";
// Constants
import evaluationTypes from "@/constants/evaluationTypes";

export default {
  mixins: [FormMixin],
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      required: true,
      validator: value => {
        // eslint-disable-next-line
        return [evaluationTypes.QUIZ, evaluationTypes.OBSERVATION].includes(value);
      }
    },
    resource: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ["click:save"],
  setup(props, context) {
    // Misc
    const { t } = useI18n();

    // Inject
    const lang = inject("lang");

    // Methods
    const getValue = type => {
      const values = {
        [evaluationTypes.QUIZ]: {
          value: quizValue,
          rules: quizRules,
          options: quizOptions,
          localizedFields: quizFields,
          relationFields: quizRelations
        },
        [evaluationTypes.OBSERVATION]: {
          value: observationValue,
          rules: observationRules,
          options: observationOptions,
          localizedFields: observationFields,
          relationFields: observationRelations
        }
      };

      return values[props.type][type];
    };

    // Computed
    const detailsLabel = computed(() => {
      if (props.type === evaluationTypes.QUIZ) {
        return t("app.questions");
      }

      return t("app.requirements");
    });

    const answerTypeOptions = computed(() => {
      if (props.type === evaluationTypes.OBSERVATION) {
        return getValue("options")?.value?.answer_type;
      }

      return quizAnswerTypeOptions.value;
    });

    const quizAnswerTypeOptions = computed(() => {
      return getValue("options")?.value?.answer_type?.map(option => {
        const isTrueFalse = ALL_OPTIONS.TRUE_FALSE.value;

        const isOptionTrueFalse = option.value === isTrueFalse;
        const isDataTrueFalse = props.data?.answer_type === isTrueFalse;

        return {
          ...option,
          // eslint-disable-next-line
          disabled: !isCreate.value && ((!isOptionTrueFalse && isDataTrueFalse) || (isOptionTrueFalse && !isDataTrueFalse))
        };
      });
    });

    // Composables
    const { ALL_OPTIONS } = useOptions();

    const {
      value: quizValue,
      rules: quizRules,
      options: quizOptions,
      localizedFields: quizFields,
      relationFields: quizRelations
    } = useQuestion(props.resource);

    const {
      value: observationValue,
      rules: observationRules,
      options: observationOptions,
      localizedFields: observationFields,
      relationFields: observationRelations
    } = useRequirement(props.resource);

    const { v, onUpdate, onSave, isCreate } = useForm(props, context, {
      value: getValue("value"),
      options: getValue("options"),
      rules: getValue("rules"),
      localizedFields: getValue("localizedFields"),
      relationFields: getValue("relationFields"),
      lang
    });

    return {
      lang,
      detailsLabel,
      answerTypeOptions,
      // useQuestion
      value: getValue("value"),
      options: getValue("options"),
      // useForm
      v,
      onUpdate,
      onSave
    };
  }
};
</script>
