<template>
  <div>
    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <div v-else class="grid grid-cols-3 gap-8">
      <div>
        <VAlert v-if="data.length === 0" :text="emptyStateLabel" />

        <VDraggable :value="sortedData" @change="onReorderQuestions">
          <VCard
            v-for="item in sortedData"
            :key="item.id"
            class="mb-5"
            :class="{ 'card-selected': item.id === selectedQuestion?.id }"
            :collapsed="allCollapsed"
          >
            <template #title>
              {{ trimHTML(getText(item.texts, "details")) }}
            </template>

            <template #subtitles>
              <div class="text-gray-600">
                {{ item.grade }} {{ $t("app.pts") }}
              </div>

              <div
                class="text-gray-600 font-medium leading-none mt-1 uppercase"
              >
                {{ $t(`app.${item.answer_type}`) }}
              </div>
            </template>

            <template #title-actions>
              <div class="flex items-center mt-1">
                <VDrag
                  v-if="isDraggable"
                  :label="$t('app.reorder_questions')"
                />

                <VAction
                  icon="edit"
                  :label="$t('app.edit')"
                  @click="onClickUpdate(item)"
                />

                <VDeleteAction
                  :disabled="readonly"
                  :item="item"
                  :text-value="trimHTML(getText(item.texts, 'details'))"
                  :delete-function="onClickDelete"
                />
              </div>
            </template>

            <template v-if="hasAnswers(item) || hasCriteria(item)" #content>
              <div v-if="hasAnswers(item)">
                <div v-for="(answer, aIndex) in item.answers" :key="answer.id">
                  <div
                    class="flex pl-5 pr-2"
                    :class="[
                      {
                        'border-b border-gray-200 dark:border-dark-5':
                          aIndex !== item.answers.length - 1
                      },
                      {
                        'pb-2': aIndex === 0
                      },
                      {
                        'py-2':
                          aIndex !== 0 && aIndex !== item.answers.length - 1
                      },
                      {
                        'pt-2': aIndex === item.answers.length - 1
                      }
                    ]"
                  >
                    <div>
                      {{ getText(answer.texts, "name") }}
                    </div>

                    <VAction
                      v-if="answer.correct_answer === 'Yes'"
                      icon="correct"
                      color="text-theme-9"
                    />
                  </div>
                </div>
              </div>

              <div v-if="hasCriteria(item)">
                <div
                  v-for="(criterion, aIndex) in item.criteria"
                  :key="criterion.id"
                >
                  <div
                    class="flex pl-5 pr-2"
                    :class="[
                      {
                        'border-b border-gray-200 dark:border-dark-5':
                          aIndex !== item.criteria.length - 1
                      },
                      { 'pb-2': aIndex === 0 },
                      {
                        'py-2':
                          aIndex !== 0 && aIndex !== item.criteria.length - 1
                      },
                      {
                        'pt-2': aIndex === item.criteria.length - 1
                      }
                    ]"
                  >
                    <div>
                      {{ getText(criterion.texts, "name") }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </VCard>
        </VDraggable>
      </div>

      <div class="col-span-2">
        <FormCard
          :readonly="readonly"
          :is-loading="isSaving"
          @click:cancel="onClickCancel"
          @click:save="onSave"
        >
          <template #tabs>
            <VTabs
              :current-tab="currentTab"
              :tabs="tabs"
              @click="onUpdateTab"
            />
          </template>

          <template v-if="isCurrentTab(TAB_NAMES.DETAILS)" #title>
            {{ title }}
          </template>

          <template v-if="!isCurrentTab(TAB_NAMES.DETAILS)" #title-actions>
            <VButton
              v-if="isCurrentTab(TAB_NAMES.ANSWERS)"
              :label="$t('app.add_answer')"
              class="btn-primary"
              :disabled="
                selectedQuestion.answer_type === ALL_OPTIONS.TRUE_FALSE.value ||
                  readonly
              "
              @click="onClickAdd"
            />

            <VButton
              v-if="isCurrentTab(TAB_NAMES.CRITERIA)"
              class="btn-primary"
              :disabled="readonly"
              :label="$t('app.add_criteria')"
              @click="onClickAdd"
            />
          </template>

          <template #content>
            <div v-if="isCurrentTab(TAB_NAMES.DETAILS)">
              <QuestionForm
                v-if="action === ACTIONS.CREATE"
                ref="questions"
                :readonly="readonly"
                :resource="resource"
                :type="type"
                @click:save="onClickSave"
              />

              <QuestionForm
                v-else-if="action === ACTIONS.UPDATE"
                ref="questions"
                :readonly="readonly"
                :resource="resource"
                :type="type"
                :data="selectedQuestion"
                @click:save="onClickSave"
              />
            </div>

            <Answers
              v-if="isCurrentTab(TAB_NAMES.ANSWERS)"
              ref="answers"
              :readonly="readonly"
              :evaluation-id="id"
              :question="selectedQuestion"
              @refresh="getData"
            />

            <Criteria
              v-if="isCurrentTab(TAB_NAMES.CRITERIA)"
              ref="criteria"
              :readonly="readonly"
              :evaluation-id="id"
              :question="selectedQuestion"
              @refresh="getData"
            />
          </template>
        </FormCard>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
// Composables
import useOptions from "@/composables/useOptions";
import useQuestion from "@/composables/useQuestion";
import useTexts from "@/composables/useTexts";
import useRequest from "@/composables/useRequest";
import useDisplay from "@/composables/useDisplay";
import useTabs from "@/composables/useTabs";
import useReadMultiple from "@/composables/useReadMultiple";
// Components
import VTabs from "@/components/VTabs";
import VDraggable from "@/components/VDraggable";
import VDrag from "@/components/VDrag";
import VCard from "@/components/VCard";
import VButton from "@/components/VButton";
import VAlert from "@/components/VAlert";
import FormCard from "@/components/FormCard";
import VAction from "@/components/tables/VAction";
import QuestionForm from "./forms/QuestionForm";
import Answers from "./Answers";
import Criteria from "./Criteria";
import VDeleteAction from "@/components/VDeleteAction";
// Constants
import evaluationTypes from "@/constants/evaluationTypes";
import answerLocales from "@/constants/answers";

export default {
  components: {
    VDrag,
    VDraggable,
    Criteria,
    VAlert,
    VDeleteAction,
    Answers,
    VCard,
    VButton,
    VTabs,
    FormCard,
    QuestionForm,
    VAction
  },
  props: {
    // evaluation id
    id: {
      type: [Number, String],
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: value => {
        return [evaluationTypes.QUIZ, evaluationTypes.OBSERVATION].includes(
          value
        );
      }
    },
    resource: {
      type: Object,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const store = useStore();

    // Constants
    const ACTIONS = {
      CREATE: "create",
      UPDATE: "update"
    };

    const TAB_NAMES = {
      DETAILS: "details",
      ANSWERS: "answers",
      CRITERIA: "criteria"
    };

    // Composables
    const { getText, getTextValues } = useTexts();
    const { request } = useRequest();
    const { localizedFields, headers } = useQuestion(props.resource);
    const { sortByDisplayOrder, trimHTML } = useDisplay();
    const { currentTab, updateTab, isCurrentTab } = useTabs(TAB_NAMES.DETAILS);
    const { ALL_OPTIONS } = useOptions();
    const { data, isLoading, read } = useReadMultiple();

    // Data
    const questions = ref(null);
    const answers = ref(null);
    const criteria = ref(null);

    const action = ref(ACTIONS.CREATE);
    const selectedQuestion = ref(null);
    const allCollapsed = ref(false);
    const isSaving = ref(false);

    // Computed
    const title = computed(() => {
      if (props.type === evaluationTypes.QUIZ) {
        // eslint-disable-next-line
        return action.value === ACTIONS.CREATE ? t("app.add_question") : t("app.edit_question");
      }

      // eslint-disable-next-line
      return action.value === ACTIONS.CREATE ? t("app.add_requirement") : t("app.edit_requirement");
    });

    const tabs = computed(() => {
      const tabs = [
        {
          name: TAB_NAMES.DETAILS,
          label: t("app.details"),
          icon: "",
          disabled: false
        }
      ];

      if (props.type === evaluationTypes.QUIZ) {
        tabs.push({
          name: TAB_NAMES.ANSWERS,
          label: t("app.answers", 2),
          icon: "",
          disabled: action.value !== ACTIONS.UPDATE
        });
      }

      if (props.type === evaluationTypes.OBSERVATION) {
        tabs.push({
          name: TAB_NAMES.CRITERIA,
          label: t("app.criteria"),
          icon: "",
          disabled: action.value !== ACTIONS.UPDATE
        });
      }

      return tabs;
    });

    const emptyStateLabel = computed(() => {
      switch (props.type) {
        case evaluationTypes.QUIZ:
          return t("app.no_questions");
        case evaluationTypes.OBSERVATION:
          return t("app.no_requirements");
        default:
          return "";
      }
    });

    const sortedData = computed(() => sortByDisplayOrder(data.value));

    const isDraggable = computed(() => {
      const hasRandomizeQuestion = props.resource?.randomize_question_order;
      const no = ALL_OPTIONS.NO.value;

      return hasRandomizeQuestion === no;
    });

    // Methods
    const hasCriteria = item => {
      const isObservation = props.type === evaluationTypes.OBSERVATION;
      const hasCriteria = item.criteria.length > 0;

      return isObservation && hasCriteria;
    };

    const hasAnswers = item => {
      const isQuiz = props.type === evaluationTypes.QUIZ;
      const hasAnswers = item.answers.length > 0;

      return isQuiz && hasAnswers;
    };

    const onClickDelete = async item => {
      await request({
        endpoint: "teaching.questions.delete",
        pathParams: {
          id: props.id,
          question_id: item.id
        }
      });

      await getData();
    };

    const getData = async () => {
      await read({
        endpoint: "teaching.questions",
        pathParams: {
          id: props.id
        },
        queryParams: {
          ro_r: ["texts", "answers", "criteria", "image"]
        }
      });

      selectedQuestion.value = data.value.find(
        question => question.id === selectedQuestion?.value?.id
      );
    };

    const onClickUpdate = item => {
      selectedQuestion.value = getFormattedData(item);
      action.value = ACTIONS.UPDATE;
    };

    const getFormattedData = item => {
      return {
        ...item,
        ...getTextValues(item?.texts, localizedFields)
      };
    };

    const onClickAdd = () => {
      const isQuiz = props.type === evaluationTypes.QUIZ;

      if (isQuiz) {
        answers.value.add();
      } else {
        criteria.value.add();
      }
    };

    const onClickAddQuestion = () => {
      updateTab(TAB_NAMES.DETAILS);
      action.value = ACTIONS.CREATE;
      selectedQuestion.value = null;
    };

    const onClickCancel = () => {
      selectedQuestion.value = null;
      action.value = ACTIONS.CREATE;
    };

    const onSave = async () => {
      isSaving.value = true;

      switch (currentTab.value) {
        case TAB_NAMES.DETAILS:
          await questions.value.onSave();
          break;
        case TAB_NAMES.ANSWERS:
          await answers.value.onSave();
          break;
        case TAB_NAMES.CRITERIA:
          await criteria.value.onSave();
          break;
      }

      isSaving.value = false;
    };

    const onClickSave = async data => {
      switch (action.value) {
        case ACTIONS.CREATE:
          await addQuestion(data);
          break;
        case ACTIONS.UPDATE:
          await updateQuestion(data);
          break;
      }
    };

    const updateQuestion = async data => {
      await request({
        endpoint: "teaching.questions.update",
        pathParams: {
          id: props.id,
          question_id: selectedQuestion.value.id
        },
        data
      });

      await getData();
    };

    const addQuestion = async data => {
      const response = await request({
        endpoint: "teaching.questions.create",
        pathParams: {
          id: props.id
        },
        data: {
          ...data,
          display_order: sortedData.value.length
        }
      });

      onClickUpdate(response?.payload?.data);

      if (data.answer_type === ALL_OPTIONS.TRUE_FALSE.value) {
        await addTrueFalseAnswers(response?.payload?.data?.id);
      }

      if (data.answer_type === ALL_OPTIONS.THREE_CHOICES.value) {
        await addThreeChoicesAnswers(response?.payload?.data?.id);
      }

      if (data.answer_type === ALL_OPTIONS.FOUR_CHOICES.value) {
        await addFourChoicesAnswers(response?.payload?.data?.id);
      }

      await getData();
    };

    const answerData = {
      correct_answer: ALL_OPTIONS.NO.value,
      // eslint-disable-next-line
      sys_admin_locked: props?.resource?.sys_admin_locked || ALL_OPTIONS.NO.value
    };

    const addTrueFalseAnswers = async questionId => {
      const promises = ["true", "false"].map(text => {
        return addAnswer(questionId, {
          ...answerData,
          texts: getTextsData(text)
        });
      });

      await Promise.all(promises);
    };

    const addThreeChoicesAnswers = async questionId => {
      const promises = [
        "satisfactory",
        "not_satisfactory",
        "not_applicable"
      ].map(text => {
        return addAnswer(questionId, {
          ...answerData,
          correct_answer: getCorrectAnswerValue(text),
          texts: getTextsData(text)
        });
      });

      await Promise.all(promises);
    };

    const addFourChoicesAnswers = async questionId => {
      const promises = [
        "satisfactory",
        "satisfactory_improvements",
        "not_satisfactory",
        "not_applicable"
      ].map(text => {
        return addAnswer(questionId, {
          ...answerData,
          correct_answer: getCorrectAnswerValue(text),
          texts: getTextsData(text)
        });
      });

      await Promise.all(promises);
    };

    const getCorrectAnswerValue = text => {
      // eslint-disable-next-line
      const isCorrect = ["satisfactory", "satisfactory_improvements"].includes(text);
      return isCorrect ? ALL_OPTIONS.YES.value : ALL_OPTIONS.NO.value;
    };

    const getTextsData = key => {
      const langs = store.getters["app/available_languages"];

      const data = Object.keys(langs).map(langKey => ({
        language: langKey,
        name: answerLocales[langKey][key] ?? answerLocales.en[key],
        details: null
      }));

      return JSON.stringify(data);
    };

    const addAnswer = async (questionId, data) => {
      await request({
        endpoint: "teaching.answers.create",
        pathParams: {
          id: props.id,
          question_id: questionId
        },
        data,
        showToaster: false
      });
    };

    const onReorderQuestions = async questions => {
      const data = questions.map((question, index) => ({
        [question.id]: index
      }));

      await request({
        endpoint: "teaching.evaluations.reorder-questions",
        pathParams: {
          id: props.id
        },
        data: {
          new_order: JSON.stringify(data)
        }
      });

      await getData();
    };

    /**
     * Toggle the collapse/expand all
     */
    const onClickToggle = () => {
      allCollapsed.value = !allCollapsed.value;
    };

    // Watch
    watch(
      () => props.data,
      () => {
        if (!selectedQuestion.value || !props.data) {
          return;
        }

        const value = props.data.find(question => {
          return question.id === selectedQuestion.value.id;
        });

        if (!value) {
          updateTab(TAB_NAMES.DETAILS);
          selectedQuestion.value = null;
        }

        selectedQuestion.value = value;
      }
    );

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      title,
      isDraggable,
      sortedData,
      onReorderQuestions,
      hasCriteria,
      hasAnswers,
      evaluationTypes,
      emptyStateLabel,
      isSaving,
      questions,
      criteria,
      answers,
      getFormattedData,
      tabs,
      allCollapsed,
      onClickToggle,
      TAB_NAMES,
      ACTIONS,
      getData,
      onClickUpdate,
      onClickCancel,
      onClickSave,
      onSave,
      onClickAdd,
      onClickAddQuestion,
      selectedQuestion,
      action,
      onClickDelete,
      // useReadMultiple
      isLoading,
      data,
      // useOptions
      ALL_OPTIONS,
      // useTabs
      isCurrentTab,
      onUpdateTab: updateTab,
      currentTab,
      // useDisplay
      trimHTML,
      // useQuestion
      headers,
      // useTexts
      getText
    };
  }
};
</script>
