import { ref } from "vue";
import { required } from "@/i18n/i18n-validators";
import { useI18n } from "vue-i18n";
// Composables
import useOptions from "@/composables/useOptions";

/**
 * @param {Object} requirement - the criteria's requirement. Optional.
 * @returns {Object}
 */
export default function useCriteria(requirement) {
  // Misc
  const { t } = useI18n();

  const { ALL_OPTIONS } = useOptions();

  // CONSTANTS
  // const endpoint = "teaching.evaluations.questions.criteria";
  const endpoint = "teaching.criteria";
  const localizedFields = ["name", "details"];
  const headers = [
    {
      text: t("app.name"),
      value: "name",
      class: "w-3/4"
    },
    {
      text: t("app.actions"),
      value: "__ACTIONS",
      class: "w-1/4"
    }
  ];

  // DATA
  const value = ref({
    sys_admin_locked: requirement?.sys_admin_locked || ALL_OPTIONS.NO.value,
    name: "",
    details: ""
  });
  const rules = ref({
    sys_admin_locked: {},
    correct_answer: {},
    name: { required },
    details: {}
  });

  return {
    headers,
    endpoint,
    value,
    rules,
    localizedFields
  };
}
