export default {
  en: {
    true: "True",
    false: "False",
    satisfactory: "Satisfactory",
    satisfactory_improvements: "Satisfactory - Need Improvements",
    not_satisfactory: "Not Satisfactory",
    not_applicable: "Not Applicable"
  },
  fr: {
    true: "Vrai",
    false: "Faux",
    satisfactory: "Satisfaisant",
    satisfactory_improvements: "Satisfaisant - Besoin d'améliorations",
    not_satisfactory: "Insatisfaisant",
    not_applicable: "Non Applicable"
  }
};
