import { ref } from "vue";
import { required } from "@/i18n/i18n-validators";
// Composables
import useOptions from "@/composables/useOptions";

/**
 * @param {Object} question - the answer's question. Optional.
 * @returns {Object}
 */
export default function useAnswer(question) {
  const { ALL_OPTIONS, defaultSystemOptions } = useOptions();

  // CONSTANTS
  // const endpoint = "teaching.evaluations.questions.answers";
  const endpoint = "teaching.answers";
  const localizedFields = ["name", "details"];

  // DATA
  const value = ref({
    id: "",
    sys_admin_locked: question?.sys_admin_locked || ALL_OPTIONS.NO.value,
    correct_answer: ALL_OPTIONS.NO.value,
    name: "",
    details: ""
  });
  const rules = ref({
    id: {},
    sys_admin_locked: {},
    correct_answer: {},
    name: { required },
    details: {}
  });
  const options = ref({
    correct_answer: defaultSystemOptions
  });

  return {
    endpoint,
    value,
    rules,
    options,
    localizedFields
  };
}
