import { ref } from "vue";
import { required } from "@/i18n/i18n-validators";
// Composables
import useOptions from "@/composables/useOptions";

/**
 * @param {Object} evaluation - the question's evaluation (quiz or observation). Optional.
 * @returns {Object}
 */
export default function useQuestion(evaluation) {
  const { ALL_OPTIONS } = useOptions();

  // CONSTANTS
  // const endpoint = "teaching.evaluations.questions";
  const endpoint = "teaching.questions";
  const localizedFields = ["name", "details"];
  const relationFields = [{ image_file_id: "image" }];

  // DATA
  const value = ref({
    sys_admin_locked: evaluation?.sys_admin_locked || ALL_OPTIONS.NO.value,
    // eslint-disable-next-line
    answer_type: evaluation?.default_answer_type || ALL_OPTIONS.TRUE_FALSE.value,
    image_file_id: "",
    name: "",
    details: "",
    grade: 0
  });
  const rules = ref({
    sys_admin_locked: {},
    answer_type: { required },
    image_file_id: {},
    name: {},
    details: { required },
    grade: { required }
  });
  const options = ref({
    answer_type: [
      ALL_OPTIONS.TRUE_FALSE,
      ALL_OPTIONS.MULTIPLE_CHOICE,
      ALL_OPTIONS.SINGLE_CHOICE
    ]
  });

  return {
    endpoint,
    value,
    rules,
    options,
    localizedFields,
    relationFields
  };
}
