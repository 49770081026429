<template>
  <div>
    <FormCard
      :is-loading="isLoading"
      :hide-actions="!hasActions"
      @click:cancel="onCancel"
      @click:save="onSaveForm"
    >
      <template #tabs>
        <VTabs
          :current-tab="currentTab"
          :tabs="tabs"
          @click="onClickUpdateTab"
        />
      </template>

      <template #content>
        <Details
          v-if="isCurrentTab(TABS.DETAILS)"
          ref="details"
          :data="data"
          :resource-value="resourceValue"
          :rules="rules"
          :type="type"
          :readonly="isActive"
          :options="options"
          :localized-fields="localizedFields"
          @click:save="$emit('click:save', $event)"
        />

        <Instructors
          v-if="isCurrentTab(TABS.INSTRUCTORS)"
          :id="id"
          :endpoint="endpoint"
          :instructors="data.instructors"
          @add:instructor="$emit('refresh')"
          @remove:instructor="$emit('refresh')"
        />

        <Competencies
          v-if="isCurrentTab(TABS.COMPETENCIES)"
          :id="id"
          ref="competencies"
          :readonly="isActive"
          :endpoint="endpoint"
          :competencies="data.competencies"
          @add:competency="$emit('refresh')"
          @remove:competency="$emit('refresh')"
        />

        <div
          v-if="isCurrentTab(TABS.QUESTIONS) || isCurrentTab(TABS.REQUIREMENTS)"
          class="flex items-center justify-between"
        >
          <div class="text-base font-medium">
            <!-- eslint-disable-next-line -->
            {{ isCurrentTab(TABS.QUESTIONS) ? $t("app.manage_questions") : $t("app.manage_requirements") }}
          </div>

          <div class="flex">
            <VButton
              :disabled="isActive"
              :label="addLabel"
              class="btn-primary mr-2"
              @click="onClickAddQuestion"
            />

            <VButton
              :icon="`fal fa-chevron-double-${allCollapsed ? 'down' : 'up'}`"
              class="btn-outline-primary bg-white"
              @click="onClickToggle"
            />
          </div>
        </div>
      </template>
    </FormCard>

    <Questions
      v-if="isCurrentTab(TABS.QUESTIONS) || isCurrentTab(TABS.REQUIREMENTS)"
      :id="id"
      ref="questions"
      :readonly="isActive"
      class="mt-8"
      :type="type"
      :resource="data"
    />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useForm from "@/composables/useForm";
import useTabs from "@/composables/useTabs";
import useEvaluation from "@/composables/useEvaluation";
// Mixins
import FormMixin from "@/mixins/FormMixin.js";
// Components
import VTabs from "@/components/VTabs";
import VButton from "@/components/VButton";
import FormCard from "@/components/FormCard";
import Details from "./forms/Details";
import Instructors from "@/components/templates/Instructors";
import Competencies from "@/components/templates/Competencies";
import Questions from "./Questions";
// Constants
import evaluationTypes from "@/constants/evaluationTypes";

export default {
  components: {
    VTabs,
    Details,
    Instructors,
    Competencies,
    FormCard,
    VButton,
    Questions
  },
  mixins: [FormMixin],
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    resourceValue: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    },
    localizedFields: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      required: true,
      validator: value =>
        [evaluationTypes.QUIZ, evaluationTypes.OBSERVATION].includes(value)
    }
  },
  emits: ["update:tab", "click:save", "refresh"],
  setup(props, context) {
    // Misc
    const { t } = useI18n();

    // Constants
    const TABS = {
      DETAILS: "details",
      INSTRUCTORS: "instructors",
      COMPETENCIES: "competencies",
      QUESTIONS: "questions",
      REQUIREMENTS: "requirements"
    };

    // Composables
    // eslint-disable-next-line
    const { buildTab, currentTab, updateTab, isCurrentTab } = useTabs(TABS.DETAILS);
    const { isActive, isCreate, onCancel } = useForm(props, context);
    const { endpoint } = useEvaluation();

    // Data
    const details = ref(null);
    const competencies = ref(null);
    const questions = ref(null);

    // Computed
    const tabs = computed(() => {
      return Object.values(TABS)
        .filter(tab => {
          if (tab === TABS.QUESTIONS) {
            return props.type === evaluationTypes.QUIZ;
          }

          if (tab === TABS.REQUIREMENTS) {
            return props.type === evaluationTypes.OBSERVATION;
          }

          return true;
        })
        .map(tab => {
          return buildTab({
            name: tab,
            hideIcon: true,
            disabled: tab !== TABS.DETAILS && isCreate.value
          });
        });
    });

    const hasActions = computed(() => {
      return isCurrentTab(TABS.DETAILS) || isCurrentTab(TABS.COMPETENCIES);
    });

    const addLabel = computed(() => {
      const labels = {
        [evaluationTypes.QUIZ]: t("app.add_question"),
        [evaluationTypes.OBSERVATION]: t("app.add_requirement")
      };

      return labels[props.type] ?? "";
    });

    const allCollapsed = computed(() => {
      if (!questions.value) {
        return false;
      }

      return questions.value.allCollapsed;
    });

    // Methods
    const onSaveForm = async () => {
      switch (currentTab.value) {
        case TABS.DETAILS:
          await details.value.onSave();
          break;
        case TABS.COMPETENCIES:
          await competencies.value.onSave();
          break;
        default:
      }
    };

    const onClickUpdateTab = tab => {
      updateTab(tab);
      context.emit("update:tab", currentTab.value);
    };

    const onClickAddQuestion = () => {
      questions.value.onClickAddQuestion();
    };

    const onClickToggle = () => {
      questions.value.onClickToggle();
    };

    return {
      TABS,
      allCollapsed,
      onClickAddQuestion,
      onClickToggle,
      addLabel,
      hasActions,
      tabs,
      details,
      questions,
      competencies,
      onSaveForm,
      onClickUpdateTab,
      // useEvaluation
      endpoint,
      // useForm
      isActive,
      onCancel,
      // useTabs
      currentTab,
      isCurrentTab
    };
  }
};
</script>
