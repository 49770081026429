<template>
  <div>
    <VAlert v-if="allCriteria.length === 0" :text="$t('app.no_criteria')" />

    <div v-else>
      <div
        v-for="(criteria, aIndex) in allCriteria"
        :key="criteria.id || aIndex"
        class="border p-3"
        :class="{
          'mb-5': aIndex !== allCriteria.length - 1
        }"
      >
        <div class="flex justify-between items-center mt-1 mb-5">
          <div class="font-medium text-base">
            {{ $t("app.criteria") }} {{ aIndex + 1 }}
          </div>

          <VDeleteAction
            v-if="criteria.id"
            :item="criteria"
            :text-value="getText(criteria?.texts, 'name')"
            class="-mr-3"
            :disabled="readonly || isDeleteDisabled"
            :delete-function="onClickDelete"
          />

          <VAction
            v-else
            icon="delete"
            color="text-theme-6"
            class="-mr-3"
            :disabled="readonly || isDeleteDisabled"
            @click="onClickRemove(aIndex)"
          />
        </div>

        <CriteriaForm
          :ref="setRef"
          :readonly="readonly"
          :question="question"
          :data="getFormattedData(criteria)"
          @click:save="onSave"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
// Composables
import useCriteria from "@/composables/useCriteria";
import useTexts from "@/composables/useTexts";
import useRequest from "@/composables/useRequest";
import useOptions from "@/composables/useOptions";
// Components
import CriteriaForm from "./forms/CriteriaForm";
import VAction from "@/components/tables/VAction";
import VAlert from "@/components/VAlert";
import VDeleteAction from "@/components/VDeleteAction";

export default {
  components: {
    VAlert,
    VAction,
    CriteriaForm,
    VDeleteAction
  },
  props: {
    evaluationId: {
      type: [Number, String],
      required: true
    },
    question: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ["refresh"],
  setup(props, context) {
    // Data
    const addedCriteria = ref([]);
    const criteria = ref([]);

    // Composables
    const { ALL_OPTIONS } = useOptions();
    const { request } = useRequest();
    const { request: save } = useRequest();
    const { getText, getTextValues } = useTexts();
    const { endpoint, value, localizedFields } = useCriteria(props.question);

    // Computed
    const isDeleteDisabled = computed(() => {
      return props.question.answer_type === ALL_OPTIONS.TRUE_FALSE.value;
    });

    const allCriteria = computed(() => {
      return [...props.question.criteria, ...addedCriteria.value];
    });

    // Methods
    const setRef = el => {
      const hasEl = criteria.value.some(criterion => criterion.id === el?.id);

      if (!el || hasEl) {
        return;
      }

      criteria.value.push(el);
    };

    const onClickRemove = index => {
      addedCriteria.value.splice(index, 1);
    };

    const onClickDelete = async item => {
      await request({
        endpoint: `${endpoint}.delete`,
        pathParams: {
          id: props.evaluationId,
          question_id: props.question.id,
          criteria_id: item.id
        }
      });

      context.emit("refresh");
    };

    const getFormattedData = item => {
      return {
        ...item,
        ...getTextValues(item?.texts, localizedFields)
      };
    };

    /**
     * @WARNING Parent component uses this method. DO NOT REMOVE.
     */
    const add = () => {
      addedCriteria.value.push(value);
    };

    /**
     * @WARNING Parent component uses this method. DO NOT REMOVE.
     */
    const onSave = async () => {
      let isValid = true;

      const promises = criteria.value.map(async criteria => {
        const data = await criteria.getData();

        if (!data) {
          isValid = false;
          return;
        }

        const id = data?.id;

        if (id) {
          return await update(id, data);
        }

        return await create(data);
      });

      await Promise.all(promises);

      if (!isValid) {
        return;
      }

      context.emit("refresh");
    };

    const create = async data => {
      return await save({
        endpoint: `${endpoint}.create`,
        pathParams: {
          id: props.evaluationId,
          question_id: props.question.id
        },
        data,
        showToaster: false
      });
    };

    const update = async (id, data) => {
      return await save({
        endpoint: `${endpoint}.update`,
        pathParams: {
          id: props.evaluationId,
          question_id: props.question.id,
          criteria_id: id
        },
        data,
        showToaster: false
      });
    };

    return {
      onClickRemove,
      getText,
      setRef,
      onSave,
      allCriteria,
      add,
      isDeleteDisabled,
      getFormattedData,
      onClickDelete
    };
  }
};
</script>
