<template>
  <div class="grid grid-cols-2 gap-x-8 gap-y-6">
    <LocalizedInput
      component="VText"
      :model-value="value.name"
      :errors="v.name"
      :label="$t('app.name')"
      :lang="lang"
      :readonly="readonly || locked"
      field="name"
      class="col-span-2"
      @update:modelValue="onUpdate"
      @keyup.enter="onSave"
    />

    <LocalizedInput
      component="VWysiwyg"
      :model-value="value.details"
      :errors="v.details"
      :label="$t('app.details')"
      :lang="lang"
      class="col-span-2"
      field="details"
      :readonly="readonly || locked"
      @update:modelValue="onUpdate"
    />

    <VText
      v-if="type === evaluationTypes.QUIZ"
      v-model="v.duration.$model"
      :label="$t('app.duration')"
      type="number"
      :errors="v.duration.$errors"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VSelect
      v-if="type === evaluationTypes.QUIZ"
      v-model="v.duration_type.$model"
      :label="$t('app.duration_type')"
      :errors="v.duration_type.$errors"
      :options="options.duration_type"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VSelect
      v-model="v.status.$model"
      :label="$t('app.status')"
      :errors="v.status.$errors"
      :options="options.status"
      :readonly="locked"
      @keyup.enter="onSave"
    />

    <VText
      v-model="v.version.$model"
      :label="$t('app.version')"
      :errors="v.version.$errors"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VSelect
      v-model="v.default_answer_type.$model"
      :label="$t('app.default_answer_type')"
      :errors="v.default_answer_type.$errors"
      :options="options.default_answer_type"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VSwitch
      v-model="v.display_answers.$model"
      :label="$t('app.display_answers')"
      true-value="Yes"
      false-value="No"
      :readonly="readonly || (!isSysAdmin && locked)"
      :errors="v.display_answers.$errors"
      @keyup.enter="onSave"
    />

    <VText
      v-model="v.attempt_interval_in_days.$model"
      :label="$t('app.attempt_interval_in_days')"
      type="number"
      :errors="v.attempt_interval_in_days.$errors"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    >
      <template #hint>
        {{ $t("hints.teaching_evaluations_attempt_interval_in_days") }}
      </template>
    </VText>

    <VSwitch
      v-model="v.display_correct_answers.$model"
      :label="$t('app.display_correct_answers')"
      true-value="Yes"
      false-value="No"
      :readonly="readonly || (!isSysAdmin && locked)"
      :errors="v.display_correct_answers.$errors"
      @keyup.enter="onSave"
    />

    <VText
      v-model="v.passing_grade.$model"
      :label="$t('app.passing_grade')"
      type="number"
      :errors="v.passing_grade.$errors"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VSwitch
      v-model="v.requires_approval.$model"
      :label="$t('app.requires_approval')"
      true-value="Yes"
      false-value="No"
      :readonly="readonly || (!isSysAdmin && locked)"
      :errors="v.requires_approval.$errors"
      @keyup.enter="onSave"
    />

    <VText
      v-model="v.maximum_attempts_per_registration.$model"
      :label="$t('app.maximum_attempts_per_registration')"
      type="number"
      :errors="v.maximum_attempts_per_registration.$errors"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    >
      <template #hint>
        {{ $t("hints.teaching_evaluations_max_attempts_per_registration") }}
      </template>
    </VText>

    <VSwitch
      v-model="v.sys_admin_locked.$model"
      :disabled="!isSysAdmin"
      :label="$t('app.sys_admin_locked')"
      true-value="Yes"
      false-value="No"
      :readonly="readonly || (!isSysAdmin && locked)"
      :errors="v.sys_admin_locked.$errors"
      @keyup.enter="onSave"
    />

    <VSelect
      v-model="v.assign_competencies_upon.$model"
      :label="$t('app.assign_competencies_upon')"
      :readonly="readonly || (!isSysAdmin && locked)"
      :options="options.assign_competencies_upon"
      :errors="v.assign_competencies_upon.$errors"
      @keyup.enter="onSave"
    >
      <template #hint>
        {{ $t("hints.teaching_evaluations_assign_competencies_upon") }}
      </template>
    </VSelect>

    <VSwitch
      v-model="v.randomize_question_order.$model"
      :label="$t('app.randomize_question_order')"
      true-value="Yes"
      false-value="No"
      :readonly="readonly || (!isSysAdmin && locked)"
      :errors="v.randomize_question_order.$errors"
      @keyup.enter="onSave"
    />

    <VText
      v-model="v.number_of_reevaluations_if_failed.$model"
      :label="$t('app.number_of_reevaluations_if_failed')"
      type="number"
      :errors="v.number_of_reevaluations_if_failed.$errors"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    >
      <template #hint>
        {{ $t("hints.teaching_evaluations_number_of_reeevaluation_if_failed") }}
      </template>
    </VText>

    <VSwitch
      v-if="type === evaluationTypes.QUIZ"
      v-model="v.randomize_answer_order.$model"
      :label="$t('app.randomize_answer_order')"
      true-value="Yes"
      false-value="No"
      :readonly="readonly || (!isSysAdmin && locked)"
      :errors="v.randomize_answer_order.$errors"
      @keyup.enter="onSave"
    />

    <VDatePicker
      v-if="!isCreate"
      v-model="v.date_activated.$model"
      readonly
      :errors="v.date_activated.$errors"
      :label="$t('app.date_activated')"
    />

    <VDatePicker
      v-if="!isCreate"
      v-model="v.date_archived.$model"
      readonly
      :errors="v.date_archived.$errors"
      :label="$t('app.date_archived')"
    />

    <VTags
      v-model="v.tags.$model"
      :label="$t('app.tags')"
      :readonly="locked"
      :errors="v.tags.$errors"
    />

    <VColor
      v-model="v.colour.$model"
      :label="$t('app.colour')"
      :errors="v.colour.$errors"
      @keyup.enter="onSave"
    />
  </div>
</template>

<script>
import { inject, ref } from "vue";
// Composables
import useForm from "@/composables/useForm";
// Mixins
import FormMixin from "@/mixins/FormMixin";
// Constants
import evaluationTypes from "@/constants/evaluationTypes";

export default {
  mixins: [FormMixin],
  props: {
    resourceValue: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    },
    localizedFields: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      required: true,
      validator: value =>
        [evaluationTypes.QUIZ, evaluationTypes.OBSERVATION].includes(value)
    }
  },
  emits: ["click:save"],
  setup(props, context) {
    // Misc
    const lang = inject("lang");

    // Data
    const value = ref(props.resourceValue);
    const rules = ref(props.rules);

    // Composables
    const {
      v,
      onUpdate,
      onSave,
      isSysAdmin,
      locked,
      isActive,
      isCreate
    } = useForm(props, context, {
      value,
      rules,
      localizedFields: props.localizedFields,
      lang
    });

    return {
      evaluationTypes,
      value,
      lang,
      // useForm
      isCreate,
      v,
      onUpdate,
      onSave,
      isSysAdmin,
      locked,
      isActive
    };
  }
};
</script>
