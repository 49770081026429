<template>
  <div class="grid grid-cols-2 gap-x-8 gap-y-6">
    <LocalizedInput
      component="VText"
      :model-value="value.name"
      :errors="v.name"
      :lang="lang"
      :readonly="readonly"
      field="name"
      class="col-span-2"
      @update:modelValue="onUpdate"
      @keyup.enter="onSave"
    />

    <!-- <LocalizedInput
      component="VWysiwyg"
      :model-value="value.details"
      :errors="v.details"
      :label="$t('app.details')"
      :readonly="readonly"
      :lang="lang"
      class="col-span-2"
      field="details"
      @update:modelValue="onUpdate"
    />

    <VSwitch
      v-model="v.sys_admin_locked.$model"
      :disabled="!isSysAdmin"
      :label="$t('app.sys_admin_locked')"
      :readonly="readonly"
      true-value="Yes"
      false-value="No"
      :errors="v.sys_admin_locked.$errors"
      @keyup.enter="onSave"
    /> -->
  </div>
</template>

<script>
import { ref, inject } from "vue";
// Composables
import useForm from "@/composables/useForm";
import useCriteria from "@/composables/useCriteria";
// Mixins
import FormMixin from "@/mixins/FormMixin";

export default {
  mixins: [FormMixin],
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    question: {
      type: Object,
      required: true
    }
  },
  emits: ["click:save"],
  setup(props, context) {
    // Inject
    const _ = inject("lodash");
    const lang = inject("lang");

    // Data
    const id = ref(_.uniqueId());

    // Composables
    const { value, rules, localizedFields } = useCriteria(props.question);
    const { v, onUpdate, onSave, stringifiedValue } = useForm(props, context, {
      value,
      rules,
      localizedFields,
      lang
    });

    // Methods
    const getData = async () => {
      const isValid = await v.value.$validate();

      if (!isValid) {
        return;
      }

      return stringifiedValue.value;
    };

    return {
      lang,
      id, // Parent component uses this method. DO NOT REMOVE.
      getData, // Parent component uses this method. DO NOT REMOVE.
      // useCriteria
      value,
      // useLocalizedInputs
      v,
      onSave,
      onUpdate
    };
  }
};
</script>
